<!-- eslint-disable max-len -->
<template>
<div :key="`key_${key}`">
    <GeneralSubHeader>
        <template #title>
            <span
                class="mr-3"
                style="font-size: 1.6rem;"
            >
                <i class="flaticon2-calendar-2" />
            </span>
        </template>
        <template #left-buttons>
            <MarkingPeriodSubHeaderMenu />
        </template>
        <template #buttons />
    </GeneralSubHeader>
    <div class="kt-container kt-grid__item kt-grid__item--fluid">
        <div class="row">
            <div
                v-if="markingPeriods.length == 0"
                class="col-12"
            >
                <div
                    class="alert alert-light alert-elevate fade show"
                    role="alert"
                >
                    <div class="alert-icon">
                        <i class="la la-info-circle kt-font-danger" />
                    </div>
                    <div class="alert-text">
                        Upload a
                        <router-link
                            :to="{name: 'UploadReportCards'}"
                            class="kt-font-bold kt-font-primary"
                        >
                            Stars Admin All Teachers
                        </router-link>
                        excel file and enable STARS Grading on the marking period
                        to enable this export utility.
                    </div>
                </div>
            </div>

            <div
                v-else
                class="kt-portlet"
            >
                <div class="kt-portlet__body">
                    <div
                        id="kt_wizard_v1"
                        class="kt-grid kt-wizard-v1 kt-wizard-v1--white"
                        data-ktwizard-state="first"
                    >
                        <div class="kt-grid__item">
                            <div class="kt-wizard-v1__nav">
                                <div class="kt-wizard-v1__nav-items kt-wizard-v1__nav-items--clickable">
                                    <div
                                        class="kt-wizard-v1__nav-item"
                                        data-ktwizard-type="step"
                                        :data-ktwizard-state="getClassForStep('download')"
                                        @click="selectStepByName('download')"
                                    >
                                        <div class="kt-wizard-v1__nav-body">
                                            <div class="kt-wizard-v1__nav-icon">
                                                <i class="flaticon-multimedia-4" />
                                            </div>
                                            <div class="kt-wizard-v1__nav-label">
                                                1. Download Egg
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="kt-wizard-v1__nav-item"
                                        data-ktwizard-type="step"
                                        :data-ktwizard-state="getClassForStep('copy')"
                                        @click="selectStepByName('copy')"
                                    >
                                        <div class="kt-wizard-v1__nav-body">
                                            <div class="kt-wizard-v1__nav-icon">
                                                <i class="flaticon-edit-1" />
                                            </div>
                                            <div class="kt-wizard-v1__nav-label">
                                                2. Copy Grades
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="kt-wizard-v1__nav-item"
                                        data-ktwizard-type="step"
                                        :data-ktwizard-state="getClassForStep('paste')"
                                        @click="selectStepByName('paste')"
                                    >
                                        <div class="kt-wizard-v1__nav-body">
                                            <div class="kt-wizard-v1__nav-icon">
                                                <i class="flaticon-imac" />
                                            </div>
                                            <div class="kt-wizard-v1__nav-label">
                                                3. Paste Grades
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="kt-wizard-v1__nav-item"
                                        data-ktwizard-type="step"
                                        :data-ktwizard-state="getClassForStep('upload')"
                                        @click="selectStepByName('upload')"
                                    >
                                        <div class="kt-wizard-v1__nav-body">
                                            <div class="kt-wizard-v1__nav-icon">
                                                <i class="flaticon-upload-1" />
                                            </div>
                                            <div class="kt-wizard-v1__nav-label">
                                                4. Save And Upload
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v1__wrapper">
                            <form
                                id="kt_form"
                                class="kt-form"
                                onsubmit="return false"
                            >
                                <div
                                    v-if="activeStep.name == 'download'"
                                    class="kt-wizard-v1__content"
                                >
                                    <div class="kt-heading kt-heading--md">
                                        Download File
                                    </div>
                                    <div class="kt-form__section kt-form__section--first">
                                        <div class="kt-wizard-v1__form">
                                            <div class="row">
                                                <div
                                                    v-if="downloaded"
                                                    class="alert alert-secondary w-100"
                                                >
                                                    <div class="alert-icon">
                                                        <i class="flaticon2-check-mark kt-font-success mb-5" />
                                                    </div>
                                                    <div class="alert-text">
                                                        Great, now open the file you just downloaded in Excel and click next step button below to continue
                                                    </div>
                                                </div>
                                                <div class="form-group col-8">
                                                    <label for="mp">Marking Period</label>
                                                    <select
                                                        v-model="schoolTermMarkingPeriodId"
                                                        class="form-control"
                                                    >
                                                        <option
                                                            v-for="mp in markingPeriods"
                                                            :key="mp.schoolTermMarkingPeriodId"
                                                            :value="mp.schoolTermMarkingPeriodId"
                                                            :selected="mp.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId"
                                                        >
                                                            {{ mp.markingPeriodName }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label>&nbsp;</label><br>
                                                    <button
                                                        :disabled="!schoolTermMarkingPeriodId"
                                                        type="button"
                                                        class="btn btn-success"
                                                        :class="{'kt-spinner kt-spinner--sm kt-spinner--light': populating}"
                                                        @click="download()"
                                                    >
                                                        Download EGG
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="activeStep.name == 'copy'"
                                    class="kt-wizard-v1__content"
                                >
                                    <div class="kt-heading kt-heading--md">
                                        Copy grades to the clipboard
                                    </div>
                                    <div class="kt-form__section kt-form__section--first w-100">
                                        <div class="kt-wizard-v1__form">
                                            <div
                                                v-if="!schoolTermMarkingPeriodId"
                                                class="alert alert-danger w-100"
                                            >
                                                <div class="alert-icon">
                                                    <i class="flaticon2-check-mark kt-font-danger mb-5" />
                                                </div>
                                                <div class="alert-text">
                                                    Please select a Marking Period on the previous step.
                                                </div>
                                            </div>
                                            <div
                                                v-else
                                                class="row w-100"
                                            >
                                                <PortletLoader
                                                    v-if="!csvDownloaded"
                                                    class="w-100"
                                                />
                                                <template v-else>
                                                    <div
                                                        v-if="csvCopiedToClipboard"
                                                        class="alert alert-secondary w-100"
                                                    >
                                                        <div class="alert-icon">
                                                            <i class="flaticon2-check-mark kt-font-success" />
                                                        </div>
                                                        <div class="alert-text">
                                                            Awesome, your grades are on your clipboard, click next step button below to continue
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-if="formatting"
                                                        class="w-100"
                                                    >
                                                        Creating a STARS compatible data set. Please wait...
                                                        <div class="progress progress--lg">
                                                            <div
                                                                class="progress-bar kt-bg-info"
                                                                role="progressbar"
                                                                height="20px"
                                                                :style="{width: percentage(currentItem, totalItems) + '%'}"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-else
                                                        class="form-group col-12"
                                                    >
                                                        <label>&nbsp;</label><br>
                                                        <button
                                                            ref="btncopy"
                                                            :disabled="!schoolTermMarkingPeriodId"
                                                            type="button"
                                                            class="btn btn-success btn-lg"
                                                            :class="{'kt-spinner kt-spinner--sm kt-spinner--light': populating}"
                                                            @click="copyToClipboard()"
                                                        >
                                                            <i class="flaticon2-copy" />
                                                            Copy Grades to Clipboard
                                                        </button>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="activeStep.name == 'paste'"
                                    class="kt-wizard-v1__content"
                                >
                                    <div class="kt-heading kt-heading--md">
                                        Paste Grades into Excel File
                                    </div>
                                    <div class="kt-form__section kt-form__section--first w-100">
                                        <div class="row w-100">
                                            <div class="alert alert-secondary w-100">
                                                <div class="alert-icon">
                                                    <i class="flaticon-edit-1 kt-font-primary" />
                                                </div>
                                                <div class="alert-text">
                                                    Open the excel file you downloaded in Step 1, and place your cursor in row 2, column J. <br>
                                                    Then paste the grade data that is saved on your clipboard.
                                                </div>
                                            </div>
                                            <img
                                                style="width: 100%"
                                                src="/images/hs_egg.png"
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="activeStep.name == 'upload'"
                                    class="kt-wizard-v1__content"
                                >
                                    <div class="kt-heading kt-heading--md">
                                        Submit the Excel File to STARS Admin
                                    </div>
                                    <div class="kt-form__section kt-form__section--first w-100">
                                        <div class="row w-100">
                                            <div class="alert alert-secondary w-100">
                                                <div class="alert-icon">
                                                    <i class="flaticon2-check-mark kt-font-primary" />
                                                </div>
                                                <div class="alert-text">
                                                    Save the changes to your excel file, and submit this file back via the STARS Client.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="kt-form__actions mt-5 pt-5">
                                    <button
                                        v-if="activeStep.name !== 'download'"
                                        class="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                                        data-ktwizard-type="action-prev"
                                        @click="previousStep"
                                    >
                                        Previous
                                    </button>
                                    <button
                                        class="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                                        data-ktwizard-type="action-next"
                                        @click="nextStep"
                                    >
                                        Next Step
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

import { mapState } from 'vuex';
import async from 'async';
import XLSX from '@sheet/crypto/dist/xlsx.full.min';
import * as network from '../network';
import Types from '../store/Types';
import PortletLoader from '../components/PortletLoader.vue';
import GeneralSubHeader from '../components/GeneralSubHeader.vue';
import MarkingPeriodSubHeaderMenu from '../components/MarkingPeriodSubHeaderMenu.vue';
import { hashString, getHashEncryptionKey } from '../lib/cipher';

export default {
    name: 'DownloadReportCards',
    components: {
        PortletLoader,
        GeneralSubHeader,
        MarkingPeriodSubHeaderMenu,
    },
    data() {
        return {
            name: 'Stars Admin EGG Downloader',
            schoolTermMarkingPeriodId: null,
            markingPeriods: [],
            populating: false,
            downloaded: false,
            formatting: false,
            currentItem: 0,
            totalItems: 0,
            excelFile: null,
            csvDownloaded: false,
            csvCopiedToClipboard: false,
            key: 0,
            steps: [{
                name: 'download',
                active: true,
            }, {
                name: 'copy',
                active: false,
            }, {
                name: 'paste',
                active: false,
            }, {
                name: 'upload',
                active: false,
            }],
        };
    },
    computed: mapState({
        user: (state) => state.user,
        activeStep() {
            const v = this;
            return v.steps.find((step) => step.active);
        },
    }),
    watch: {
        activeStep() {
            const v = this;
            if (v.activeStep && v.activeStep.name === 'copy') {
                v.csvDownloaded = false;
                v.csvCopiedToClipboard = false;
                v.compare();
            }
        },
    },
    mounted() {
        const v = this;
        this.csv = null;

        v.$store.dispatch(Types.actions.REFRESH_MARKING_PERIODS, (err) => {
            if (err) return v.showError(err);

            v.markingPeriods = v.$store.state.database.markingPeriods
                .filter((mp) => mp.starsGradingEnabled === true && mp.deleted === false)
                .map((mp) => ({ ...mp }));

            if (v.markingPeriods.length > 0) {
                v.schoolTermMarkingPeriodId = v.markingPeriods[0].schoolTermMarkingPeriodId;
            }
            v.key += 1;
        });
    },
    methods: {
        percentage(num, den) {
            if (num <= 0 || den <= 0) return 0;
            const percentage = ((num / den) * 100);
            const roundToDecimals = 1;
            // @ts-ignore
            return Math.floor(Number(`${Math.round(`${percentage}e${roundToDecimals}`)}e-${roundToDecimals}`));
        },
        nextStep() {
            const { activeStep, steps, selectStepByIndex } = this;
            if (!activeStep) return;
            const activeIndex = steps.findIndex((step) => step.name == activeStep.name);
            if (activeIndex < steps.length) {
                selectStepByIndex(activeIndex + 1);
            }
        },
        previousStep() {
            const v = this;
            const activeIndex = v.steps.findIndex((step) => step.name == v.activeStep.name);
            if (activeIndex > 0) {
                v.selectStepByIndex(activeIndex - 1);
            }
        },
        selectStepByIndex(idx) {
            const v = this;
            v.steps = v.steps.map((s, i) => {
                const step = s;
                step.active = i == idx;
                return step;
            });
        },
        selectStepByName(stepName) {
            const v = this;
            v.steps = v.steps.map((s) => {
                const step = s;
                step.active = stepName == step.name;
                return step;
            });
        },
        getClassForStep(stepName) {
            const v = this;
            const activeIndex = v.steps.findIndex((step) => step.name == v.activeStep.name);
            const wantedStep = v.steps.findIndex((step) => step.name == stepName);
            if (activeIndex > wantedStep) return 'done';
            if (activeIndex == wantedStep) return 'current';
            return 'pending';
        },
        download() {
            const v = this;
            if (this.populating) return;
            this.populating = true;
            const { schoolTermMarkingPeriodId } = this;
            const { schoolId, schoolTermId } = this.$store.state.user.school;
            downloadExcel(schoolTermMarkingPeriodId, schoolId, schoolTermId, (err, excelFile) => {
                v.populating = false;
                if (err) return v.showError(err);
                v.excelFile = excelFile;
                v.downloaded = true;
                setTimeout(() => {
                    window.location.href = excelFile;
                }, 500);
            });
        },
        compare() {
            const v = this;
            v.csv = null;
            v.csvDownloaded = false;
            v.formatting = false;

            const { showError } = this;
            const { schoolTermMarkingPeriodId } = v;
            const { schoolId, schoolTermId, encryptionHash } = this.$store.state.user.school;

            loadComparison(schoolTermMarkingPeriodId, schoolId, schoolTermId, (loadErr, results) => {
                if (loadErr) return showError(loadErr);

                const { hashEncryptionKey } = results;
                const { gradeData, savedData } = results;
                const { comments, grades } = savedData;
                v.csvDownloaded = true;
                v.totalItems = gradeData.length;
                v.formatting = true;

                const output = [];
                async.eachOfLimit(gradeData, 5, (row, idx, nextRow) => {
                    const excelRow = { ...row };
                    const extStudentId = hashString(excelRow.StudentID, hashEncryptionKey);

                    const studentGrade = grades.find((grade) => {
                        const foundStudent = grade.extStudentId == extStudentId;
                        if (!foundStudent) return false;
                        const courses = grade.extCourseSectionIds.map((c) => c.split('-')[0]);
                        const sections = grade.extCourseSectionIds.map((c) => c.split('-')[1]);
                        const foundSection = courses.includes(excelRow.Course) && sections.includes(excelRow.Sec);
                        if (!foundSection) return false;
                        return true;
                    });

                    const studentComments = comments.filter((comment) => {
                        const courses = comment.extCourseSectionIds.map((c) => c.split('-')[0]);
                        const sections = comment.extCourseSectionIds.map((c) => c.split('-')[1]);
                        return comment.extStudentId == extStudentId
                            && courses.includes(excelRow.Course) && sections.includes(excelRow.Sec);
                    });

                    const outputRow = [];
                    // mark
                    outputRow.push(studentGrade?.mark || '');
                    // exam
                    outputRow.push(studentGrade?.examMark || '');
                    // conduct
                    outputRow.push(studentGrade?.conduct || '');
                    // level
                    outputRow.push(studentGrade?.level || '');
                    // c1
                    const comment1 = studentComments.find((c) => c.markingPeriodCommentIndex == 0);
                    outputRow.push(comment1?.commentCode || '');
                    // c2
                    const comment2 = studentComments.find((c) => c.markingPeriodCommentIndex == 1);
                    outputRow.push(comment2?.commentCode || '');
                    // c3
                    const comment3 = studentComments.find((c) => c.markingPeriodCommentIndex == 2);
                    outputRow.push(comment3?.commentCode || '');
                    // absent
                    outputRow.push('');
                    // final
                    let final = null;
                    if (studentGrade?.isFinal === true) final = 'Y';
                    if (studentGrade?.isFinal === false) final = 'N';
                    outputRow.push(final || '');

                    // remove special chars from narrative
                    let narrative = (studentGrade?.narrativeComment || '').trim();
                    narrative = narrative.replace(/[\n\r]/g, ' ');
                    narrative = narrative.replace(/[\t]/g, ' ');

                    outputRow.push(narrative);
                    output.push(outputRow.join('\t'));
                    v.currentItem = idx;

                    setTimeout(() => {
                        nextRow();
                    }, 10);
                }, (err) => {
                    if (err) return v.showError(err);
                    v.formatting = false;
                    v.csv = output.join('\n');
                });
            });
        },
        copyToClipboard() {
            const v = this;
            const container = v.$refs.btncopy;
            v.$copyText(v.csv, container).then(() => {
                v.csvCopiedToClipboard = true;
            }, (err) => {
                if (err) v.showError('Error copying to clipboard');
                window.console.error(err);
            });
        },

    },
};

function loadComparison(schoolTermMarkingPeriodId, schoolId, schoolTermId, callback) {
    async.auto({
        hashEncryptionKey(next) {
            getHashEncryptionKey({ schoolId, schoolTermId }, next);
        },
        excelFileUri(next) {
            downloadExcel(schoolTermMarkingPeriodId, schoolId, schoolTermId, (err, excelFile) => {
                if (err) return next(err);
                next(null, excelFile);
            });
        },
        arrayBuffer: ['excelFileUri', function (results, next) {
            const { excelFileUri } = results;
            const xhr = new XMLHttpRequest();
            xhr.open('get', excelFileUri, true);
            xhr.responseType = 'blob';
            xhr.onload = () => {
                const reader = new FileReader();
                reader.onload = function (event) {
                    next(null, event.target.result);
                };
                reader.onerror = function () {
                    next(new Error('Error reading file'));
                };
                const f = new File([xhr.response], 'egg.xls', { type: 'application/vnd.ms-excel' });
                // reader.readAsDataURL(f);
                reader.readAsArrayBuffer(f);
            };
            xhr.send();
        }],
        gradeData: ['arrayBuffer', function (results, next) {
            const { arrayBuffer } = results;
            const data = new Uint8Array(arrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });
            const gradeData = XLSX.utils.sheet_to_json(workbook.Sheets.Grade_Data);
            next(null, gradeData);
        }],
        savedData: ['gradeData', function (results, next) {
            downloadGradeData(schoolTermMarkingPeriodId, schoolId, schoolTermId, (err, res) => {
                if (err) return next(err);
                next(null, res.reportCards);
            });
        }],
    }, 5, (err, results) => {
        if (err) return callback(err);
        const { hashEncryptionKey, gradeData, savedData } = results;
        callback(err, { hashEncryptionKey, gradeData, savedData });
    });
}

function downloadExcel(schoolTermMarkingPeriodId, schoolId, schoolTermId, callback) {
    const params = {
        url: { schoolId, schoolTermId, schoolTermMarkingPeriodId },
    };
    network.reportCard.downloadExcelFile(params, (err, res) => {
        if (err) return callback(err);
        callback(null, res.download);
    });
}

function downloadGradeData(schoolTermMarkingPeriodId, schoolId, schoolTermId, callback) {
    const params = {
        url: { schoolId, schoolTermId, schoolTermMarkingPeriodId },
    };
    network.reportCard.downloadGradeData(params, callback);
}

</script>

<style scoped>
@charset "UTF-8";
.kt-wizard-v1 {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }
  .kt-wizard-v1 .kt-wizard-v1__nav {
    border-bottom: 1px solid #eeeef4; }
    .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 70%;
      margin: 0 auto; }
      .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1; }
        .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item:after {
          font-family: Flaticon2;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          line-height: 1;
          text-decoration: inherit;
          text-rendering: optimizeLegibility;
          text-transform: none;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          font-smoothing: antialiased;
          content: "";
          font-size: 1.25rem;
          color: #a2a5b9;
          margin-left: 0.5rem;
          margin-right: 0.5rem; }
        .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item:last-child:after {
          content: none; }
        .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item .kt-wizard-v1__nav-body {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          padding: 2rem 1rem;
          text-align: center; }
          .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item .kt-wizard-v1__nav-body .kt-wizard-v1__nav-icon {
            font-size: 3.7rem;
            color: #a2a5b9;
            margin-bottom: 0.5rem; }
            .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item .kt-wizard-v1__nav-body .kt-wizard-v1__nav-icon g [fill] {
              -webkit-transition: fill 0.3s ease;
              transition: fill 0.3s ease;
              fill: #a2a5b9; }
            .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item .kt-wizard-v1__nav-body .kt-wizard-v1__nav-icon:hover g [fill] {
              -webkit-transition: fill 0.3s ease;
              transition: fill 0.3s ease; }
          .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item .kt-wizard-v1__nav-body .kt-wizard-v1__nav-label {
            font-size: 1.1rem;
            font-weight: 500;
            color: #74788d; }
        .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state="done"]:after,
        .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state="current"]:after {
          color: #339af2; }
        .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state="done"] .kt-wizard-v1__nav-body .kt-wizard-v1__nav-icon,
        .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state="current"] .kt-wizard-v1__nav-body .kt-wizard-v1__nav-icon {
          color: #339af2; }
          .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state="done"] .kt-wizard-v1__nav-body .kt-wizard-v1__nav-icon g [fill],
          .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state="current"] .kt-wizard-v1__nav-body .kt-wizard-v1__nav-icon g [fill] {
            -webkit-transition: fill 0.3s ease;
            transition: fill 0.3s ease;
            fill: #339af2; }
          .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state="done"] .kt-wizard-v1__nav-body .kt-wizard-v1__nav-icon:hover g [fill],
          .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state="current"] .kt-wizard-v1__nav-body .kt-wizard-v1__nav-icon:hover g [fill] {
            -webkit-transition: fill 0.3s ease;
            transition: fill 0.3s ease; }
        .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state="done"] .kt-wizard-v1__nav-body .kt-wizard-v1__nav-label,
        .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item[data-ktwizard-state="current"] .kt-wizard-v1__nav-body .kt-wizard-v1__nav-label {
          color: #339af2; }
      .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items.kt-wizard-v1__nav-items--clickable .kt-wizard-v1__nav-item {
        cursor: pointer; }
      @media (max-width: 1399px) {
        .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items {
          width: 100%; } }
      @media (max-width: 768px) {
        .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
          padding: 2rem 0; }
          .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            position: relative;
            width: 100%; }
            .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item:after {
              position: absolute;
              right: 2rem; }
            .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item:last-child:after {
              content: "\f105";
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              font-size: 2rem;
              color: #a2a5b9; }
            .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item .kt-wizard-v1__nav-body {
              -webkit-box-orient: horizontal;
              -webkit-box-direction: normal;
              -ms-flex-direction: row;
              flex-direction: row;
              -webkit-box-pack: start;
              -ms-flex-pack: start;
              justify-content: flex-start;
              -webkit-box-flex: 0;
              -ms-flex: 0 0 100%;
              flex: 0 0 100%;
              padding: 0.5rem 2rem; }
              .kt-wizard-v1 .kt-wizard-v1__nav .kt-wizard-v1__nav-items .kt-wizard-v1__nav-item .kt-wizard-v1__nav-body .kt-wizard-v1__nav-icon {
                font-size: 1.5rem;
                margin-right: 1rem;
                margin-bottom: 0; } }
  .kt-wizard-v1 .kt-wizard-v1__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #f9fafc; }
    .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form {
      width: 60%;
      padding: 4rem 0 5rem; }
      .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-wizard-v1__content {
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid #eeeef4; }
        .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-wizard-v1__content .kt-wizard-v1__form {
          margin-top: 3rem; }
        .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-wizard-v1__content .kt-wizard-v1__review .kt-wizard-v1__review-item {
          padding-bottom: 1rem;
          margin-bottom: 1rem;
          border-bottom: 1px solid #eeeef4; }
          .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-wizard-v1__content .kt-wizard-v1__review .kt-wizard-v1__review-item .kt-wizard-v1__review-title {
            font-weight: 600;
            font-size: 1.1rem;
            margin-bottom: 0.7rem; }
          .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-wizard-v1__content .kt-wizard-v1__review .kt-wizard-v1__review-item .kt-wizard-v1__review-content {
            line-height: 1.8rem; }
          .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-wizard-v1__content .kt-wizard-v1__review .kt-wizard-v1__review-item:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0; }
      .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-form__actions {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; }
        @media (max-width: 576px) {
          .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-form__actions {
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center; } }
        @media (max-width: 576px) {
          .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-form__actions .btn {
            margin: 0 0.5rem 1rem; } }
        .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-form__actions [data-ktwizard-type="action-prev"] {
          margin-right: auto; }
          @media (max-width: 576px) {
            .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-form__actions [data-ktwizard-type="action-prev"] {
              margin-right: 0.5rem; } }
        .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-form__actions [data-ktwizard-type="action-next"] {
          margin: auto 0 auto auto; }
          @media (max-width: 576px) {
            .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form .kt-form__actions [data-ktwizard-type="action-next"] {
              margin: 0 0 1rem; } }
    @media (max-width: 768px) {
      .kt-wizard-v1 .kt-wizard-v1__wrapper {
        padding: 2rem; }
        .kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form {
          width: 100%;
          padding: 2rem 1rem 4rem; } }
  .kt-wizard-v1.kt-wizard-v1--white .kt-wizard-v1__wrapper {
    background-color: #ffffff; }
  .kt-wizard-v1 [data-ktwizard-type="step-info"] {
    display: none; }
    .kt-wizard-v1 [data-ktwizard-type="step-info"][data-ktwizard-state="current"] {
      display: block; }
  .kt-wizard-v1 [data-ktwizard-type="step-content"] {
    display: none; }
    .kt-wizard-v1 [data-ktwizard-type="step-content"][data-ktwizard-state="current"] {
      display: block; }
  .kt-wizard-v1 [data-ktwizard-type="action-next"] {
    display: inline-block; }
  .kt-wizard-v1 [data-ktwizard-type="action-submit"] {
    display: none; }
  .kt-wizard-v1[data-ktwizard-state="first"] [data-ktwizard-type="action-next"] {
    display: inline-block; }

  .kt-wizard-v1[data-ktwizard-state="between"] [data-ktwizard-type="action-prev"] {
    display: inline-block; }
  .kt-wizard-v1[data-ktwizard-state="between"] [data-ktwizard-type="action-next"] {
    display: inline-block; }
  .kt-wizard-v1[data-ktwizard-state="between"] [data-ktwizard-type="action-submit"] {
    display: none; }
  .kt-wizard-v1[data-ktwizard-state="last"] [data-ktwizard-type="action-prev"] {
    display: inline-block; }
  .kt-wizard-v1[data-ktwizard-state="last"] [data-ktwizard-type="action-next"] {
    display: none; }
  .kt-wizard-v1[data-ktwizard-state="last"] [data-ktwizard-type="action-submit"] {
    display: inline-block; }

</style>
